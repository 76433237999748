import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import Link from 'next/link';

const buttonVariants = cva(
	'inline-flex items-center capitalize justify-center whitespace-nowrap font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
	{
		variants: {
			variant: {
				default:
					'bg-primary rounded-full text-primary-foreground hover:bg-primary/90',
				destructive:
					'bg-destructive rounded-full text-destructive-foreground hover:bg-destructive/90',
				outline:
					'border-2 border-input rounded-full bg-background hover:bg-accent hover:text-accent-foreground',
				secondary:
					'border border-secondary rounded-full text-secondary-foreground hover:bg-secondary/80',
				ghost: 'hover:bg-accent rounded-full hover:text-accent-foreground',
				link: 'text-muted-foreground hover:text-foreground !p-0 underline-offset-4 hover:underline',
			},
			size: {
				default: 'px-5 py-1.5',
				sm: 'h-9 px-3',
				lg: 'h-11 px-8',
				icon: 'h-10 w-10',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	el?: React.ElementType;
	href?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, el, href, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button';
		if (el === 'link' && href) {
			return (
				<Link className='flex gap-x-2' legacyBehavior passHref href={href}>
					<Comp
						className={cn(buttonVariants({ variant, size, className }))}
						ref={ref}
						{...props}
					/>
				</Link>
			);
		}
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				{...props}
			/>
		);
	}
);
Button.displayName = 'Button';

export { Button, buttonVariants };
