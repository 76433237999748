import { cn } from '@/lib/utils';
import { LazyMotion, m } from 'framer-motion';
import { FC } from 'react';

const paddingClasses = {
	none: '',
	small: 'py-6 lg:py-4',
	medium: 'py-12 lg:py-8',
	large: 'lg:py-32 py-16',
	hero: 'py-16 lg:py-48',
};

const loadFeatures = () =>
	import('../../feature.js').then((res) => res.default);

export const AnimatedBlock: FC<{
	children?: React.ReactNode;
	className?: string;
	id?: string;
	animate?: boolean;
	padding?: keyof typeof paddingClasses;
}> = (props) => {
	const { children, className, id, padding = 'none' } = props;
	return (
		<LazyMotion features={loadFeatures}>
			<m.div
				initial={{ opacity: 0, y: 50 }}
				whileInView={{
					opacity: 1,
					y: 0,
					transition: { duration: 0.5, ease: 'easeInOut' },
				}}
				viewport={{ once: true, amount: 'some' }}
				id={id}
				className={cn(
					'flex flex-col items-center',
					className,
					paddingClasses[padding]
				)}>
				{children}
			</m.div>
		</LazyMotion>
	);
};
