'use client';

import { Block } from '../../components/Block';
import { Gutter } from '../../components/Gutter';
import { TypographyH2 } from '@/components/ui/h2';
import { LazyMotion, m } from 'framer-motion';
import { TypographyH3 } from '@/components/ui/h3';
import { TypographyLead } from '@/components/ui/lead';
import Link from 'next/link';
import { Button } from '@/components/ui/button';
import { FC } from 'react';
import { ServiceType } from './types';
import { cn } from '@/lib/utils';
import { TypographyP } from '@/components/ui/p';
import { ModalPopover } from '@/components/ui/modal-popover';

const loadFeatures = () =>
	import('../../feature.js').then((res) => res.default);

const Services: FC<{
	services: ServiceType[];
	title: string;
	subtitle: string;
	lead: string;
	contact?: boolean;
	id?: string;
	variant?: 'default' | 'detailed';
	children?: JSX.Element;
}> = ({
	services,
	title,
	subtitle,
	lead,
	contact,
	id,
	variant = 'default',
}) => {
	return (
		<Block id={id}>
			<Gutter padding='medium' className='h-full items-center relative'>
				<div className=' flex w-full flex-col gap-x-10'>
					<div className='w-full max-w-4xl'>
						<TypographyH2>{subtitle}</TypographyH2>
						<TypographyH3>{title}</TypographyH3>
						<TypographyLead>{lead}</TypographyLead>
					</div>

					<div
						className={cn(
							'grid grid-cols-1 transition gap-12 md:gap-y-24 mt-10 lg:mt-16 relative md:grid-cols-2 md:gap-x-24'
						)}>
						<LazyMotion features={loadFeatures}>
							{services.map((x, i) => (
								<m.div
									className={cn(
										'rounded duration-150 group relative',
										x.slug && 'hover:border-accent',
										variant === 'default'
											? ' border-l-2 border-l-foreground/10 pl-4 '
											: 'col-span-2 w-full'
									)}
									key={x.title + i}
									whileInView={{
										opacity: 1,
										y: 0,
										transition: { delay: 0.05 * i, ease: 'easeInOut' },
									}}
									viewport={{ once: true }}
									initial={{ opacity: 0, y: 30 }}>
									<div key={x.slug} className='flex flex-col h-fit w-full '>
										<div className={x.slug && 'group-hover:text-accent'}>
											{x.icon}
										</div>
										<p className='mt-3 font-bold text-xl max-w-xs lg:text-3xl tracking-tighter capitalize opacity-90'>
											{x.title}
										</p>
										<TypographyP className='!mt-4'>{x.summary}</TypographyP>
										{x.slug && (
											<Link
												className=' h-full w-full group'
												href={`/services/${x.slug}`}
												aria-label={`Navigate to ${x.title} page`}>
												<Button variant='outline' className='mt-6 w-fit'>
													Explore Services
												</Button>
											</Link>
										)}
									</div>

									{variant === 'detailed' && (
										<div className='mt-6'>
											<hr className='mb-4' />
											<p className='capitalize !mt-0 text-foreground/70 font-medium text-base tracking-tight leading-snug'>
												What&apos;s included:
											</p>
											<div className='flex gap-y-2 gap-x-2 items-start flex-wrap mt-4'>
												{(x?.features ?? []).map((feature, i) => (
													<ModalPopover
														key={feature.title + '-' + title + '-' + i}
														title={feature.title}
														description={feature.description}>
														<Button
															variant='ghost'
															className='px-4 md:px-3 text-xs md:text-sm py-1.5 md:py-2 grow-0 rounded-full bg-accent/5 border-accent/50 border text-accent font-medium tracking-tight'>
															{feature.title}
														</Button>
													</ModalPopover>
												))}
											</div>
										</div>
									)}
								</m.div>
							))}
						</LazyMotion>
					</div>
				</div>
				{contact && (
					<Button className='mt-6' variant='outline'>
						Contact us
					</Button>
				)}
			</Gutter>
		</Block>
	);
};

export default Services;
