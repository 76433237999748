'use client';

import { Block } from '../../components/Block';
import { Gutter } from '../../components/Gutter';
import { TypographyH2 } from '@/components/ui/h2';
import { TypographyP } from '@/components/ui/p';
import { cn } from '@/lib/utils';
import { LazyMotion, m } from 'framer-motion';

import dynamic from 'next/dynamic';
import { FC } from 'react';
import { Engagement } from '../Services/types';
const AnimatedNumbers = dynamic(() => import('react-animated-numbers'), {
	ssr: false,
});

const alignment = {
	center: 'items-center',
	left: 'items-start',
	right: 'items-end',
};

const columns = (length: number) => {
	switch (length) {
		case 1:
			return 'grid-cols-1';
		case 2:
			return 'grid-cols-1 lg:grid-cols-2';
		case 3:
			return 'grid-cols-1 lg:grid-cols-3';
		case 4:
			return 'grid-cols-1 lg:grid-cols-4';
		default:
			return 'grid-cols-1';
	}
};

const loadFeatures = () =>
	import('../../feature.js').then((res) => res.default);

const Stats: FC<{
	stats: Engagement[];
	cols?: number;
	padding?: 'small' | 'large' | 'medium' | 'none';
	title?: string;
	align?: 'center' | 'left' | 'right';
	className?: string;
	children?: JSX.Element;
}> = ({
	stats,
	cols,
	title,
	align = 'center',
	padding = 'large',
	className,
	children,
}) => {
	return (
		<Block padding='none' className={className}>
			<Gutter padding={padding} className='text-center'>
				{children}
				{title && <TypographyH2>{title}</TypographyH2>}
				<div
					className={cn(
						`grid ${columns(cols ? cols : stats.length)} gap-y-16 lg:gap-16`
					)}>
					<LazyMotion features={loadFeatures}>
						{stats.map((stat, index) => (
							<m.div
								initial={{ opacity: 0, y: 50 }}
								viewport={{ once: true, amount: 'some' }}
								whileInView={{
									opacity: 1,
									y: 0,
									transition: {
										duration: 0.5,
										ease: 'easeInOut',
										type: 'just',
										delay: index * 0.2,
									},
								}}
								key={index}
								className={cn('flex flex-col  ', alignment[align])}>
								<span className='flex text-5xl font-black  tracking-tighter'>
									{stat.prefix}
									<AnimatedNumbers
										animateToNumber={stat.value}
										transitions={(i) => ({
											ease: 'easeInOut',
											delay: index * 0.2,
											duration: 1,
										})}
									/>
									{'' + stat.suffix}
								</span>
								<TypographyP className='!mt-0 lg:mt-6 '>
									{stat.title}
								</TypographyP>
							</m.div>
						))}
					</LazyMotion>
				</div>
			</Gutter>
		</Block>
	);
};

export default Stats;
