import { Popover, PopoverContent, PopoverTrigger } from './popover';

export const ModalPopover = (props: {
	title: string;
	description: string;
	children: React.ReactNode;
}) => {
	return (
		<Popover>
			<PopoverTrigger asChild>{props.children}</PopoverTrigger>
			<PopoverContent className='w-56 md:w-96 p-4 bg-background -left-2 md:left-2 relative border border-border'>
				<div className='grid gap-2'>
					<h3 className='text-lg font-medium leading-none'>{props.title}</h3>
					<p className='text-sm  text-foreground'>{props.description}</p>
				</div>
			</PopoverContent>
		</Popover>
	);
};
